import { Controller } from '@hotwired/stimulus'
import axios from 'axios'
import mdtoast from '@dmuy/toast'


export default class extends Controller {

  static values = {
    organizationId: { type: String, default: '' },
    locationId: { type: String, default: '' }
  }

  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return
    console.log('location geogrid config sync controller connected.')
  }

  disconnect() {
  }

  sync(evt) {
    evt.preventDefault()

    axios.post(`/ajax/locations/${this.locationIdValue}/sync_moments`)
      .then((response) => {
        mdtoast.info(response.data.message, { duration: 10000 })
      }).catch((error) => {
        mdtoast.error(error.response?.data?.message || error.message, { duration: 10000 })
      })
  }
}
