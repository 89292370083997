import { Controller } from '@hotwired/stimulus'
import securedAxios from '../init/axios'

export default class extends Controller {
  static values = {
    organizationId: {type: String, default: ''}, locationId: {type: String, default: ''}
  }

  connect(_context) {
    // if (document.documentElement.hasAttribute('data-turbo-preview')) return
    // console.log('location geogrid config sync controller connected.')
  }

  disconnect() {
  }

  sync(evt) {
    evt.preventDefault()

    // I don't really understand why in this particular case we need to use set CSRF token manually,
    // and why it is not set automatically by our securedAxios instance, and I don't have a time
    // to debug that. So I just set it manually here.
    const csrf = document.querySelector('meta[name="csrf-token"]')
    securedAxios.defaults.headers.common['X-CSRF-Token'] = csrf.getAttribute('content')
    securedAxios.post(`/ajax/locations/${this.locationIdValue}/sync_geogrid_configs`).then((_response) => {
      // TODO: do something here, like update button state from loading to loaded
    }).catch((error) => {
      /* eslint-disable no-console */
      console.log(error)
    })
  }
}
