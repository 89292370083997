<template>
  <div class="table-responsive-wrapper">
    <div class="table-responsive">
      <span> Search Organizations </span>
      <input
        v-model="search"
        type="text"
        class="form-control mb-5"
        style="width: auto">

      <VueSlimTables
        ref="table"
        :source="tableSource"
        :columns="columns"
        class="table"
        :per-page="50">
        <template #cell:name="{ row, value }">
          <a :href="`/organizations/${row.id}`">{{ value || 'N/A' }}</a><br>
          <small>{{ row['owner'] }}</small>

          <br>
          <span
            v-if="isProvisionStatusNA(row)"
            class="bg-gray-200 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full">
            Provision: N/A
          </span>

          <span
            v-else-if="row['provisionStatus'] === 'failed'"
            class="bg-red-200 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full">
            Provision: failed
          </span>

          <span
            v-else-if="row['provisionStatus'] === 'in_progress'"
            class="bg-yellow-200 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full">
            Provision: pending
          </span>

          <span
            v-else-if="row['provisionStatus'] === 'provisioned'"
            class="bg-green-200 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full">
            Provision: OK
          </span>
        </template>
        <template #cell:onboarded="{ value }">
          <div :class="onboardingStateClass(value)">
            {{ value }}
          </div>
        </template>

        <template #cell:pendingSetup="{ value }">
          <div :class="onboardingStateClass(!value)">
            {{ value }}
          </div>
        </template>

        <template #cell:chatHelpRequested="{ value }">
          <div :class="onboardingStateClass(!value)">
            {{ value }}
          </div>
        </template>

        <template #cell:repHelpRequested="{ value }">
          <div :class="onboardingStateClass(!value)">
            {{ value }}
          </div>
        </template>

        <template #cell:leadHelpRequested="{ value }">
          <div :class="onboardingStateClass(!value)">
            {{ value }}
          </div>
        </template>

        <template #cell:locationHelpRequested="{ value }">
          <div :class="onboardingStateClass(!value)">
            {{ value }}
          </div>
        </template>

        <template #cell:userLocationConfigured="{ value }">
          <div :class="onboardingStateClass(!value)">
            {{ value }}
          </div>
        </template>

        <template #cell:userRepConfigured="{ value }">
          <div :class="onboardingStateClass(!value)">
            {{ value }}
          </div>
        </template>

        <template #cell:userLeadConfigured="{ value }">
          <div :class="onboardingStateClass(!value)">
            {{ value }}
          </div>
        </template>

        <template #cell:userChatConfigured="{ value }">
          <div :class="onboardingStateClass(!value)">
            {{ value }}
          </div>
        </template>
      </VueSlimTables>
    </div>
  </div>
</template>

<script>
import qs from 'qs'
import axios from '../init/axios'
import VueSlimTables from 'vue-slim-tables'
import 'vue-slim-tables/stylesheets'

export default {
  name: 'TechStackOrganizationsTable',
  components: { VueSlimTables },
  props: { clientId: String },
  data() {
    return {
      search: ''
    }
  },
  computed: {
  },
  watch: {
    search(_val) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.$refs.table.refetch, 500)
    }
  },
  created() {
    this.columns = [
      // { title: 'Id', key: 'id' },
      { title: 'Client', key: 'name' },
      // { title: 'Owner', key: 'owner' },
      { title: 'Onboarded', key: 'onboarded' },
      { title: 'Team Setup', key: 'internalTeamSetup' },

      { title: 'Location Help', key: 'locationHelpRequested' },
      { title: 'Chat Help', key: 'chatHelpRequested' },
      { title: 'Lead Help', key: 'leadHelpRequested' },
      { title: 'Rep Help', key: 'repHelpRequested' },
      { title: 'User Location Conf', key: 'userLocationConfigured' },
      { title: 'User Chat Conf', key: 'userChatConfigured' },
      { title: 'User Lead Conf', key: 'userLeadConfigured' },
      { title: 'User Rep Conf', key: 'userRepConfigured' },
      { title: 'Managed Account', key: 'managedCustomer' }
    ]
  },
  methods: {
    reload() {
      this.$refs.table.fetchData(this.$refs.table.page)
    },

    tableSource(params) {
      return axios.get(
        '/ajax/organizations/tech_stack_customers',
        {
          params: { ...params, query: this.search },
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
        }
      ).then((res) => res.data)
    },

    onboardingStateClass(state) {
      if (state === true) {
        return 'py-1 px-4 bg-primary-500 text-xs inline-block text-white font-bold leading-normal uppercase mr-2'
      }

      return 'py-1 px-4 bg-pink-600 text-xs inline-block text-white font-bold leading-normal uppercase mr-2'
    },

    isProvisionStatusNA(row) {
      const status = row['provisionStatus']
      return status === 'no_billing_plan' || status === 'pending_welcome_form' || status === 'nothing_to_do'
    }
  }
}

</script>
