<script setup>

import { ref, watch, onMounted } from 'vue'
import axios from 'axios'
import CountryFlag from 'vue-country-flag-next'
import VueSlimTables from 'vue-slim-tables'
import 'vue-slim-tables/stylesheets';
import qs from 'qs'

const props = defineProps({
  organizationId: String
})

onMounted(() => {})

const locationInfo = ref({})
const infoLoading = ref(true)
const gridsLoading = ref(true)
const activityLoading = ref(true)
const locations = ref([])
const search = ref('')

const getLocations = () => {
  axios.get(`/ajax/organizations/${props.organizationId}/locations`).then((response) => {
    locationInfo.value = response.data
    infoLoading.value = false
  }).catch((error) => {
    console.log(error)
  })
}

const columns = [
  { title: 'Location Name', key: 'name' },
  { title: 'Overall', key: 'locationScore' },
  { title: 'Visibility', key: 'visibilityScore' },
  { title: 'Content', key: 'contentScore' },
  { title: 'Posts', key: 'postStats' },
  { title: 'Media', key: 'mediaStats' },
  { title: 'Calls', key: 'callStats' },
  { title: 'Moments', key: 'momentStats' },
  { title: 'Directions', key: 'directionStats' }
]

const tableSource = (params) => {
  return axios.get(
    `/ajax/organizations/${props.organizationId}/locations/table_index`,
    {
      params: { ...params, query: search.value },
      paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
    }
  ).then((res) => res.data)
}

const scoreClass = (score) => {
  let color
  switch (score) {
    case 'A':
      color = 'green'
      break
    case 'B':
      color = 'blue'
      break
    case 'C':
      color = 'yellow'
      break
    case 'D':
      color = 'orange'
      break
    default:
      color = 'red'
  }

  return `bg-${color}-100 text-${color}-800 text-xl font-medium px-2.5 py-0.5 rounded border border-${color}-400`
}
</script>

<template>
  <div class="grid grid-cols-1  gap-1 py-6">
        <VueSlimTables
        ref="table"
        :source="tableSource"
        :columns="columns"
        class="table px-8 py-2"
        :per-page="10">

        <template #cell:name="{ row, value }">
          <a :href="`/clients/dashboard/locations/${row['id']}/overview`">{{ row['name'] }}</a>
          <p>{{ row['placeIdFormattedAddress'] }}</p>
        </template>

        <template #cell:postStats="{ row, value }">
          <table style="width:100%">
            <tr>
              <th class='float-right'>7d</th>
              <td>{{ value['lastWeek'] }}</td>
            </tr>
            <tr>
              <th class='float-right'>30d</th>
              <td>{{ value['lastMonth'] }}</td>
            </tr>
            <tr>
              <th class='float-right'>90d</th>
              <td>{{ value['lastQuarter'] }}</td>
            </tr>
          </table>
        </template>

        <template #cell:mediaStats="{ row, value }">
          <table style="width:100%">
            <tr>
              <th class='float-right'>7d</th>
              <td>{{ value['lastWeek'] }}</td>
            </tr>
            <tr>
              <th class='float-right'>30d</th>
              <td>{{ value['lastMonth'] }}</td>
            </tr>
            <tr>
              <th class='float-right'>90d</th>
              <td>{{ value['lastQuarter'] }}</td>
            </tr>
          </table>
        </template>

        <template #cell:momentStats="{ row, value }">
          <table style="width:100%">
            <tr>
              <th class='float-right'>7d</th>
              <td>{{ value['lastWeek'] }}</td>
            </tr>
            <tr>
              <th class='float-right'>30d</th>
              <td>{{ value['lastMonth'] }}</td>
            </tr>
            <tr>
              <th class='float-right'>90d</th>
              <td>{{ value['lastQuarter'] }}</td>
            </tr>
          </table>
        </template>

        <template #cell:callStats="{ row, value }">
          <table style="width:100%">
            <tr>
              <th class='float-right'>7d</th>
              <td>{{ value['lastWeek'] }}</td>
            </tr>
            <tr>
              <th class='float-right'>30d</th>
              <td>{{ value['lastMonth'] }}</td>
            </tr>
            <tr>
              <th class='float-right'>90d</th>
              <td>{{ value['lastQuarter'] }}</td>
            </tr>
          </table>
        </template>

        <template #cell:directionStats="{ row, value }">
          <table style="width:100%">
            <tr>
              <th class='float-right'>7d</th>
              <td>{{ value['lastWeek'] }}</td>
            </tr>
            <tr>
              <th class='float-right'>30d</th>
              <td>{{ value['lastMonth'] }}</td>
            </tr>
            <tr>
              <th class='float-right'>90d</th>
              <td>{{ value['lastQuarter'] }}</td>
            </tr>
          </table>
        </template>

        <template #cell:locationScore="{ row, value }">
          <div v-if="value" class="text-center">
            <span :class="scoreClass(value)">{{ value }}</span>
          </div>
        </template>

        <template #cell:visibilityScore="{ row, value }">
          <div v-if="value" class="text-center">
            <span :class="scoreClass(value)">{{ value }}</span>
          </div>
        </template>

        <template #cell:contentScore="{ row, value }">
          <div v-if="value" class="text-center">
            <span :class="scoreClass(value)">{{ value }}</span>
          </div>
        </template>
      </VueSlimTables>
  </div>
</template>
<style>
</style>
