<script setup>

import { ref, onMounted, computed } from 'vue'
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import camelcaseKeys from 'camelcase-keys-deep'
import mdtoast from '@dmuy/toast'
import SpinnerOverlay from './components/spinner_overlay.vue'
import SpinnerSubmit from './components/spinner_submit.vue'
import '@dmuy/toast/dist/mdtoast.css'

const props = defineProps({
  planFeatures: {
    type: Object,
    required: true
  },
  organizationId: {
    type: String,
    required: true
  },
  currentClientId: {
    type: String,
    required: false
  },
  clientId: {
    type: String,
    required: false
  },
  email: {
    type: String,
    required: false
  },
  firstName: {
    type: String,
    required: false
  },
  lastName: {
    type: String,
    required: false
  },
  password: {
    type: String,
    required: false
  },
  passwordConfirmation: {
    type: String,
    required: false
  },
  adminRights: {
    type: Boolean,
    default: false
  },
  mobileAccess: {
    type: Boolean,
    default: false
  },
  locationsManagement: {
    type: Boolean,
    default: false
  },
  allowedLocations: {
    type: Array,
    default: () => []
  },
  allowAllLocations: {
    type: Boolean,
    default: false
  },
  chatManagement: {
    type: Boolean,
    default: false
  },
  reputationManagement: {
    type: Boolean,
    default: false
  },
  leadsManagement: {
    type: Boolean,
    default: false
  },
  listingsManagement: {
    type: Boolean,
    default: false
  }
})

const formLoading = ref(false)
const locationOptions = ref([])

// Validation errors in the default Rails format which we received from the backend.
// 422: For rails validation errors
// interface ValidationErrors {
//   type: 'validation',
//       message: 'Validation errors.',
//       details: {
//     [key: string]: string[]
//   }
// }
const validationErrors = ref({})

const email = ref(props.email)
const firstName = ref(props.firstName)
const lastName = ref(props.lastName)
const password = ref(props.password)
const passwordConfirmation = ref(props.passwordConfirmation)
const mobileAccess = ref(props.mobileAccess)
const adminRights = ref(props.adminRights)
const locationsManagement = ref(props.locationsManagement)
const allowedLocations = ref(props.allowedLocations)
const allowAllLocations = ref(props.allowAllLocations)
const chatManagement = ref(props.chatManagement)
const reputationManagement = ref(props.reputationManagement)
const leadsManagement = ref(props.leadsManagement)
const listingsManagement = ref(props.listingsManagement)

const mobileAccessComputed = computed(() => adminRights.value || mobileAccess.value)
const locationsManagementComputed = computed(() => adminRights.value || locationsManagement.value)
const allowAllLocationsComputed = computed(() => adminRights.value || allowAllLocations.value)
const chatManagementComputed = computed(() => adminRights.value || chatManagement.value)
const reputationManagementComputed = computed(() => adminRights.value || reputationManagement.value)
const leadsManagementComputed = computed(() => adminRights.value || leadsManagement.value)
const listingsManagementComputed = computed(() => adminRights.value || listingsManagement.value)

function sendRequest(body) {
  if (props.clientId) {
    return axios.put(`/ajax/team/${props.clientId}`, body)
  }

  return axios.post('/ajax/team', body)
}

function getLocations() {
  axios.get(`/ajax/organizations/${props.organizationId}/locations`).then((response) => {
    locationOptions.value = response.data.result
  }).catch((error) => {
    const message = `Failed to load locations. ${error.message}`
    mdtoast.error(message, { duration: 10000, position: 'top right' })
  })
}

function successToastMessage() {
  if (props.clientId) {
    return 'Team member settings and permissions have been successfully updated.'
  }
  return 'Team member has been successfully invited.'
}

function formTitle() {
  if (props.clientId) {
    return 'Update a Team Member Settings'
  }
  return 'Invite a Team Member'
}

function submitTitle() {
  if (props.clientId) {
    return 'Update Settings'
  }
  return 'Invite a Team Member'
}

function isEmailAvailable() {
  return !!props.clientId
}

function errorText(field) {
  const errors = validationErrors.value[field]

  if (!errors || errors.length === 0) {
    return null
  }

  return errors.join(', ')
}

function hasError(field) {
  return errorText(field) !== null
}

function inputError(field) {
  if (hasError(field)) {
    return 'bg-red-50 border-red-500 text-red-600 placeholder-red-700 focus:ring-red-500 focus:border-red-500'
  }
  return 'bg-gray-50 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500'
}

function labelError(field) {
  if (hasError(field)) {
    return 'text-red-600'
  }
  return 'text-gray-900'
}

async function submitForm() {
  const params = {
    email: email.value,
    first_name: firstName.value,
    last_name: lastName.value,
    password: password.value,
    password_confirmation: passwordConfirmation.value,
    permissions: {
      admin_rights: adminRights.value,
      mobile_access: mobileAccess.value || adminRights.value,
      locations_management: locationsManagement.value || adminRights.value,
      allowed_locations: allowedLocations.value,
      allow_all_locations: allowAllLocations.value || adminRights.value,
      chat_management: chatManagement.value || adminRights.value,
      reputation_management: reputationManagement.value || adminRights.value,
      leads_management: leadsManagement.value || adminRights.value,
      listings_management: listingsManagement.value || adminRights.value
    }
  }

  try {
    formLoading.value = true
    validationErrors.value = {}

    await sendRequest(params)

    mdtoast.info(successToastMessage(), { duration: 10000, position: 'top right' })
    setTimeout(() => {
      window.location = '/clients/team'
    }, 1500)
  } catch (error) {
    const errData = error.response.data

    if (errData.details) {
      validationErrors.value = camelcaseKeys(errData.details)
    }

    mdtoast.error(errData.message, { duration: 10000, position: 'top right' })
  } finally {
    formLoading.value = false
  }
}

onMounted(() => {
  getLocations()
})

</script>

<template>
  <div>
    <div class="grid grid-cols-2 gap-16 relative">
      <SpinnerOverlay :form-loading="formLoading" />

      <div>
        <!-- Left column: "Invite/Update a Team Member" -->
        <h2 class="mb-4 text-xl font-bold text-gray-900">
          {{ formTitle() }}
        </h2>

        <div class="mb-6 group">
          <label
            for="email"
            :class="['block mb-2 text-sm font-medium', labelError('email')]">
            Email
          </label>

          <input
            id="email"
            v-model="email"
            type="email"
            placeholder="john.doe@linda.co"
            required
            :readonly="isEmailAvailable()"
            :class="['shadow-sm border text-sm rounded-lg block w-full p-2.5', inputError('email')]">

          <p
            v-if="hasError('email')"
            :class="['mt-2 text-sm', labelError('email')]">
            {{ errorText('email') }}
          </p>
        </div>

        <div class="grid md:grid-cols-2 md:gap-6">
          <div class="relative z-0 w-full mb-6 group">
            <label
              for="first-name"
              :class="['block mb-2 text-sm font-medium', labelError('firstName')]">
              First name
            </label>

            <input
              id="first-name"
              v-model="firstName"
              type="text"
              placeholder="John"
              :class="['shadow-sm border text-sm rounded-lg block w-full p-2.5', inputError('firstName')]">

            <p
              v-if="hasError('firstName')"
              :class="['mt-2 text-sm', labelError('firstName')]">
              {{ errorText('firstName') }}
            </p>
          </div>

          <div class="relative z-0 w-full mb-6 group">
            <label
              for="last-name"
              :class="['block mb-2 text-sm font-medium', labelError('lastName')]">
              Last name
            </label>

            <input
              id="last-name"
              v-model="lastName"
              type="text"
              placeholder="Doe"
              :class="['shadow-sm border text-sm rounded-lg block w-full p-2.5', inputError('lastName')]">

            <p
              v-if="hasError('lastName')"
              :class="['mt-2 text-sm', labelError('lastName')]">
              {{ errorText('lastName') }}
            </p>
          </div>
        </div>

        <hr class="mb-6">

        <div class="mb-2 group">
          <label
            for="password"
            :class="['block mb-2 text-sm font-medium', labelError('password')]">
            Password
          </label>

          <div class="relative">
            <input
              id="password"
              v-model="password"
              type="password"
              :class="['shadow-sm border text-sm rounded-lg block w-full p-2.5', inputError('password')]">
            <button
              class="absolute right-2 top-2"
              data-action="password-visibility-toggle"
              type="button">
              <i class="fa-solid fa-eye-low-vision" />
            </button>
          </div>

          <p
            v-if="hasError('password')"
            :class="['mt-2 text-sm', labelError('password')]">
            {{ errorText('password') }}
          </p>
        </div>

        <div class="mb-12 group">
          <label
            for="repeat-password"
            :class="['block mb-2 text-sm font-medium', labelError('passwordConfirmation')]">
            Repeat password
          </label>

          <div class="relative">
            <input
              id="repeat-password"
              v-model="passwordConfirmation"
              type="password"
              :class="['shadow-sm border text-sm rounded-lg block w-full p-2.5', inputError('passwordConfirmation')]">
            <button
              class="absolute right-2 top-2"
              data-action="password-visibility-toggle"
              type="button">
              <i class="fa-solid fa-eye-low-vision" />
            </button>
          </div>

          <p :class="['mt-2 text-sm', labelError('passwordConfirmation')]">
            <span v-if="hasError('passwordConfirmation')">
              {{ errorText('passwordConfirmation') }}
            </span>
            <span v-else>
              Specify password only if you want to set it manually for a new team member or
              change it for an existing one.
            </span>
          </p>
        </div>
      </div>

      <div>
        <!-- Right column: "Specify Permissions" -->
        <h2 class="mb-4 text-xl font-bold text-gray-900 ">
          Specify Permissions
        </h2>

        <div class="mb-6 group form-toggle-group">
          <label class="relative inline-flex items-center mb-0 cursor-pointer">
            <input
              v-model="adminRights"
              type="checkbox"
              class="sr-only peer">
            <div
              class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300
                dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-full peer-checked:after:border-white
                after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300
                after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
            <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Admin rights</span>
          </label>

          <p class="mt-2 text-sm text-gray-500">
            Enable if you want to allow your team member to access all sections and to manage other team members and change
            their permissions.
          </p>
        </div>

        <div class="mb-6 group form-toggle">
          <label class="relative inline-flex items-center mb-0 cursor-pointer">
            <input
              type="checkbox"
              :checked="mobileAccessComputed"
              :disabled="adminRights"
              class="sr-only peer"
              @change="mobileAccess = $event.target.checked">
            <div
              class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300
                dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white
                after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border
                after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
            <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Mobile access</span>
          </label>

          <p
            id="helper-text-explanation"
            class="mt-0 text-sm text-gray-500 dark:text-gray-400">
            Allow a team member to use the mobile application features like Chat, GrowthBooster and so on if they
            have a corresponding permission.
          </p>
        </div>

        <div
          v-if="planFeatures.locationsManagement"
          class="mb-0">
          <label class="relative inline-flex items-center mb-4 cursor-pointer">
            <input
              :checked="locationsManagementComputed"
              type="checkbox"
              :disabled="adminRights"
              class="sr-only peer"
              @change="locationsManagement = $event.target.checked">
            <div
              class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300
                dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white
                after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border
                after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
            <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Locations management</span>
          </label>
        </div>

        <div
          v-if="planFeatures.locationsManagement"
          class="mb-6 group">
          <div>
            <multiselect
              v-model="allowedLocations"
              :options="locationOptions"
              :multiple="true"
              :close-on-select="true"
              placeholder="Select Locations"
              label="name"
              track-by="name"
              :disabled="!locationsManagement || allowAllLocations" />
          </div>

          <div
            v-if="planFeatures.locationsManagement"
            class="flex items-center">
            <input
              id="allow-all-locations"
              :checked="allowAllLocationsComputed"
              type="checkbox"
              :disabled="!locationsManagement || adminRights"
              class="w-4 h-4 mt-1 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700
                dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
              @input="allowAllLocations = $event.target.checked">
            <label
              for="allow-all-locations"
              :class="{ 'text-gray-400': !locationsManagement }"
              class="ml-2 mt-1 mb-0 text-sm font-medium dark:text-gray-500">Allow all locations</label>
          </div>

          <p class="mt-2 text-sm text-gray-500 dark:text-gray-400">
            Pick locations that you want to grant access to for the team member.
          </p>
        </div>

        <div
          v-if="planFeatures.chatManagement"
          class="mb-6">
          <label class="relative inline-flex items-center mb-0 cursor-pointer">
            <input
              :checked="chatManagementComputed"
              type="checkbox"
              :disabled="adminRights"
              class="sr-only peer"
              @change="chatManagement = $event.target.checked">
            <div
              class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300
                dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white
                after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border
                after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
            <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Chat management</span>
          </label>

          <p class="mt-0 text-sm text-gray-500 dark:text-gray-400">
            Allow if you want to grant access to automated chat features like Chatbot, Live Chat, Chat Inbox and so on.
          </p>
        </div>

        <div
          v-if="planFeatures.reputationManagement"
          class="mb-6">
          <label class="relative inline-flex items-center mb-2 cursor-pointer">
            <input
              :checked="reputationManagementComputed"
              type="checkbox"
              :disabled="adminRights"
              class="sr-only peer"
              @change="reputationManagement = $event.target.checked">
            <div
              class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300
                dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white
                after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border
                after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
            <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Reputation management</span>
          </label>

          <p class="mt-0 text-sm text-gray-500 dark:text-gray-400">
            Access to review generation campaigns and reputation management features.
          </p>
        </div>

        <div
          v-if="planFeatures.leadsManagement"
          class="mb-6">
          <label class="relative inline-flex items-center mb-2 cursor-pointer">
            <input
              :checked="leadsManagementComputed"
              type="checkbox"
              :disabled="adminRights"
              class="sr-only peer"
              @change="leadsManagement = $event.target.checked">
            <div
              class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300
                dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white
                after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border
                after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
            <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Leads management</span>
          </label>

          <p class="mt-0 text-sm text-gray-500 dark:text-gray-400">
            Enable to make a team member able to manage leads and lead generation campaigns, call and form tracking
            features.
          </p>
        </div>

        <div
          v-if="planFeatures.listingsManagement"
          class="mb-6">
          <label class="relative inline-flex items-center mb-2 cursor-pointer">
            <input
              :checked="listingsManagementComputed"
              type="checkbox"
              :disabled="adminRights"
              class="sr-only peer"
              @change="listingsManagement = $event.target.checked">
            <div
              class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300
                dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white
                after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border
                after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
            <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Listings management</span>
          </label>

          <p class="mt-0 text-sm text-gray-500 dark:text-gray-400">
            Allow user to manage data aggregations from different sources and listings management features.
          </p>
        </div>
      </div>
    </div>

    <div>
      <hr class="mb-8">
      <a
        href="/clients/team"
        class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
          rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4
          focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600
          dark:hover:text-white dark:hover:bg-gray-700">
        Cancel
      </a>

      <SpinnerSubmit
        :title="submitTitle()"
        :form-loading="formLoading"
        @click="submitForm" />
    </div>
  </div>
</template>

<style scoped>
</style>
